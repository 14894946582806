<template>
  <v-progress-linear/>
</template>

<script>
  import {mapActions} from 'vuex'

  export default {
    name: 'ApplicationViewDispatcher',

    props: {
      name: {
        type: String,
        required: true
      }
    },

    data () {
      return {
        applications: [],
        count: 0
      }
    },

    computed: {
      filter () {
        return {
          domainName: this.name
        }
      }
    },

    watch: {
      count () {
        this.redirect ()
      }
    },

    created () {
      this.loadApplications ()
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      loadApplications () {
        this.fetchData ({
          op: 'application/list',
          params: {
            filter: this.filter
          },
          cb: data => {
            this.applications = data.list
            this.count = data.totalCount
          }
        })
      },

      redirect () {
        let route = {}

        if (this.count === 1) {
          route = {
            name: 'application.view.id',
            params: {
              id: this.applications[0].id
            }
          }
        } else {
          route = {
            name: 'application.search',
            query: {
              filter: JSON.stringify (this.filter)
            }
          }
        }

        return this.$router.replace (route)
      }
    }
  }
</script>
